import {
    faGitlab,
    faLinkedin,
    IconDefinition,
} from '@fortawesome/free-brands-svg-icons';
import {
    faBomb,
    faCat,
    faCode,
    faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';

import styles from './App.module.scss';
import CV from './components/cv/CV';
import { Clock, Icon } from './components/desktop';
import { Background } from './components/desktop/Background/Background';
import windowStyles from './components/desktop/Window.module.scss';
import Minefield from './components/minesweeper/Minesweeper';
import SelfDocument from './components/selfdocument/SelfDocument';
import register from './registerServiceWorker';

// @ts-ignore
import { useDispatch, useSelector } from 'react-redux';
import { Windows } from './+state/windows/window.model';
import {
    selectStartMenuOpen,
    selectWindows,
} from './+state/windows/window.selectors';
import { windowSlice } from './+state/windows/window.slice';
import { WindowList } from './components/desktop/WindowList';
import selfy from './me.jpg';

interface IWindowDefinition {
    content: any;
    icon: IconDefinition;
    label: Windows;
    classes?: string[];
}

const APP_WINDOWS: IWindowDefinition[] = [
    {
        classes: [windowStyles.windowAllowOverflow],
        content: <CV />,
        icon: faCat,
        label: Windows.CV,
    },
    {
        content: <SelfDocument />,
        icon: faCode,
        label: Windows.SelfDocument,
    },
    {
        content: <Minefield />,
        icon: faBomb,
        label: Windows.Minesweeper,
    },
];

register();

export const App: React.FC = () => {
    const windows = useSelector(selectWindows);
    const startMenuOpen = useSelector(selectStartMenuOpen);
    const dispatch = useDispatch();

    const focusWindow = useCallback(
        (window: Windows) => {
            dispatch(windowSlice.actions.focusWindow(window));
        },
        [dispatch]
    );

    const openWindow = useCallback(
        (window: Windows): void => {
            dispatch(windowSlice.actions.openWindow(window));
        },
        [dispatch]
    );

    const toggleStartMenu = useCallback(
        (event: React.MouseEvent<any, MouseEvent>): void => {
            event.stopPropagation();
            dispatch(windowSlice.actions.toggleStartMenu());
        },
        [dispatch]
    );

    const closeStartMenu = useCallback(
        (event: React.MouseEvent<any, MouseEvent>): void => {
            event.stopPropagation();
            dispatch(windowSlice.actions.setStartMenu(false));
        },
        [dispatch]
    );

    return (
        <div onClick={closeStartMenu}>
            <Background />
            <div className={styles.app}>
                <div className={styles.desktop}>
                    {APP_WINDOWS.map((window) => (
                        <Icon
                            key={window.label}
                            label={window.label}
                            icon={window.icon}
                            onClick={() => openWindow(window.label)}
                        />
                    ))}
                    <WindowList />
                </div>
                <div
                    className={
                        styles.start +
                        ' ' +
                        (startMenuOpen ? styles.startOpen : '')
                    }
                >
                    <div
                        className={styles.start__menu}
                        onClick={(evt) => evt.stopPropagation()}
                    >
                        <img src={selfy} alt="Me, lurking in the start menu" />
                        {APP_WINDOWS.map((window) => (
                            <div
                                key={window.label}
                                className={styles.start__menuItem}
                                onClick={() => openWindow(window.label)}
                            >
                                <FontAwesomeIcon
                                    fixedWidth={true}
                                    icon={window.icon}
                                />
                                {window.label}
                            </div>
                        ))}

                        <a
                            href="https://www.linkedin.com/in/florian-adomeit-7310805a"
                            className={styles.start__menuItem}
                            target="_noopener noreferrer"
                        >
                            <FontAwesomeIcon
                                fixedWidth={true}
                                icon={faLinkedin}
                            />
                            LinkedIn
                        </a>
                        <a
                            href="https://gitlab.com/noVerity/me"
                            className={styles.start__menuItem}
                            target="_noopener noreferrer"
                        >
                            <FontAwesomeIcon
                                fixedWidth={true}
                                icon={faGitlab}
                            />
                            View Source
                        </a>
                        <a
                            href="https://stackoverflow.com/"
                            className={styles.start__menuItem}
                        >
                            <FontAwesomeIcon
                                fixedWidth={true}
                                icon={faSignOutAlt}
                            />
                            Logout
                        </a>
                    </div>
                    <div
                        className={styles.start__button}
                        onClick={(evt) => toggleStartMenu(evt)}
                    >
                        <FontAwesomeIcon icon={faCat} />
                        Florian Adomeit
                    </div>
                    <div className={styles.start__windows}>
                        {windows.map((window) => (
                            <span
                                key={window}
                                className={styles.start__item}
                                onClick={() => focusWindow(window)}
                            >
                                {window}
                            </span>
                        ))}
                    </div>
                    <div className={styles.start__profile}>
                        <div className={styles.start__date}>
                            <Clock />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default App;
