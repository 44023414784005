import React, { useCallback, useEffect, useRef } from 'react';

import styles from './Background.module.scss';
import { BackgroundElement } from './BackgroundElement';
import { FishEgg } from './FishEgg';
import { FishLarva } from './FishLarva';
import { FishLarvaEgg } from './FishLarvaEgg';
import { Paramecium } from './Paramecium';

const FREQUENCY = 20;
const MAX_POPULATION = 1200;

export const Background = React.memo(() => {
    const canvas = useRef<HTMLCanvasElement | null>(null);
    const canvasContext = useRef<CanvasRenderingContext2D | null>(null);
    const resizeRequested = useRef<number | null>(null);
    const particles = useRef<BackgroundElement[]>([]);
    const initialSpawnOver = useRef<boolean>(false);

    const onResize = useCallback(() => {
        if (resizeRequested.current !== null) {
            return;
        }
        resizeRequested.current = window.requestAnimationFrame(() => {
            if (canvas.current) {
                canvas.current.setAttribute('width', `${window.innerWidth}px`);
                canvas.current.setAttribute(
                    'height',
                    `${window.innerHeight}px`
                );
            }
            resizeRequested.current = null;
        });
    }, []);

    const clearCanvas = useCallback(() => {
        if (!canvas.current || !canvasContext.current) {
            return;
        }
        const grd = canvasContext.current.createRadialGradient(
            canvas.current.width / 2,
            canvas.current.height / 2,
            0,
            canvas.current.width / 2,
            canvas.current.height / 2,
            canvas.current.width
        );
        grd.addColorStop(0, 'rgba(25,25,54,0.12)');
        grd.addColorStop(1, 'rgba(0,0,20,0.01)');
        // Fill with gradient
        canvasContext.current.fillStyle = grd;
        canvasContext.current.fillRect(
            0,
            0,
            canvas.current.width,
            canvas.current.height
        );
    }, []);

    const populate = useCallback((num: number) => {
        const initOver = initialSpawnOver.current;
        for (let i = 0; i < num; i++) {
            setTimeout(() => {
                if (!canvas.current || !canvasContext.current) {
                    return;
                }
                const random = Math.random();
                // ------------------------------------
                // Set type of planktom
                let type: BackgroundElement = new FishLarva(
                    canvasContext.current
                );
                if (!initOver) {
                    if (random > 0.97)
                        type = new FishEgg(canvasContext.current);
                    if (random < 0.1 && random > 0)
                        type = new Paramecium(canvasContext.current);
                }
                if (random > 0.1 && random < 0.8)
                    type = new FishLarvaEgg(canvasContext.current);

                // Add particle
                particles.current.push(type);
            }, FREQUENCY * i);
        }
        initialSpawnOver.current = true;
    }, []);

    const update = useCallback(() => {
        clearCanvas();
        const currentParticles = particles.current.length;
        particles.current = particles.current.filter((particle) =>
            particle.move()
        );
        const particlesRemoved = currentParticles - particles.current.length;
        if (particlesRemoved > 0) {
            populate(particlesRemoved);
        }
        requestAnimationFrame(() => update());
    }, [clearCanvas, populate]);

    useEffect(() => {
        canvasContext.current = canvas.current?.getContext('2d') || null;
        window.addEventListener('resize', onResize);
        onResize();
        populate(MAX_POPULATION);
        update();
        return () => window.removeEventListener('resize', onResize);
    }, [onResize, populate, update]);

    return (
        <canvas
            className={styles.backgroundCanvas}
            ref={(ref) => (canvas.current = ref)}
        />
    );
});
