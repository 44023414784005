import React, { useEffect, useState } from 'react';

function renderTime(date: Date): string {
    return `${pad(date.getHours())}:${pad(date.getMinutes())} ${pad(
        date.getDate()
    )}.${pad(date.getMonth() + 1)}.${date.getFullYear()}`;
}

function pad(str: number): string {
    let result = String(str);
    const zeros = 2 - result.length;
    for (let i = 0; i < zeros; i++) {
        result = '0' + str;
    }
    return result;
}

export const Clock: React.FC = () => {
    const [time, setTime] = useState(renderTime(new Date()));

    useEffect(() => {
        let timer: number;
        window.setTimeout(() => {
            setTime(renderTime(new Date()));
            timer = window.setInterval(() => {
                setTime(renderTime(new Date()));
            }, 60000);
        }, (61 - new Date().getSeconds()) * 1000);

        return () => window.clearInterval(timer);
    }, []);

    return <span>{time}</span>;
};
