import { faBomb, faFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useRef } from 'react';

import styles from './Mine.module.scss';

export enum MineStates {
    Bomb = 'bomb',
    Empty = 'empty',
    Number = 'number',
}

export interface IMine {
    revealed: boolean;
    adjacentMines: number;
    safe: boolean;
    state: MineStates;
}

export interface IMineProps {
    mine: IMine;
    onCascade: () => void;
    onSafe: () => void;
    onClick: () => void;
}

export const Mine: React.FC<IMineProps> = ({
    mine,
    onCascade,
    onSafe,
    onClick,
}) => {
    const potentialCascade = useRef<number | null>(null);

    const renderContent = useCallback(() => {
        if (mine.safe && !mine.revealed) {
            return <FontAwesomeIcon fixedWidth={true} icon={faFlag} />;
        }
        if (!mine.revealed) {
            return null;
        }
        switch (mine.state) {
            case 'bomb':
                return <FontAwesomeIcon fixedWidth={true} icon={faBomb} />;
            case 'empty':
                return <span />;
            case 'number':
                return (
                    <span className={styles[`mine${mine.adjacentMines}`]}>
                        {mine.adjacentMines}
                    </span>
                );
            default:
                return null;
        }
    }, [mine]);

    const _onClick = useCallback(
        (event: React.MouseEvent<HTMLSpanElement>): void => {
            event.preventDefault();
            if (
                (event.button === 2 && event.buttons === 1) ||
                (event.button === 1 && event.buttons === 2) ||
                (event.button === 2 &&
                    event.buttons === 0 &&
                    potentialCascade.current === 0) ||
                (event.button === 0 &&
                    event.buttons === 2 &&
                    potentialCascade.current === 2)
            ) {
                onCascade();
                return;
            }
            if (event.button === 2 && event.buttons === 0) {
                potentialCascade.current = 2;
                window.setTimeout(() => (potentialCascade.current = null), 50);
            }
            if (event.button === 0 && event.buttons === 2) {
                potentialCascade.current = 0;
                window.setTimeout(() => (potentialCascade.current = null), 50);
            }
            if (event.button === 2) {
                onSafe();
                return;
            }
            onClick();
        },
        [onCascade, onSafe, onClick]
    );

    return (
        <span
            className={`${styles.mine} ${
                mine.revealed ? styles.mineRevealed : styles.mineUnrevealed
            } ${
                mine.adjacentMines > 0 && mine.revealed ? styles.mineNumber : ''
            }`}
            onClick={(evt) => _onClick(evt)}
            onMouseDown={(evt) => evt.preventDefault()}
            onContextMenu={(evt) => {
                _onClick(evt);
                evt.preventDefault();
                return false;
            }}
        >
            {renderContent()}
        </span>
    );
};

export default Mine;
