import { BackgroundElement } from './BackgroundElement';

export class Paramecium extends BackgroundElement {
    private progress: number = 0;
    private x: number = 0;
    private y: number = 0;
    private readonly rotation: number = 0;
    private readonly radius: number = 0;
    private readonly variantx1: number = 0;
    private readonly variantx2: number = 0;
    private readonly varianty1: number = 0;
    private readonly varianty2: number = 0;

    constructor(canvas: CanvasRenderingContext2D) {
        super(canvas);
        // Set position
        this.x = this.width / 2 + (Math.random() * 300 - Math.random() * 300);
        this.y =
            this.height / 2 +
            ((Math.random() * this.height) / 4 -
                (Math.random() * this.height) / 4);
        // Get viewport size
        this.rotation = (Math.random() * 180 * Math.PI) / 180;
        // Dimension
        this.radius = 12 + Math.random() * 6;
        // Setting
        this.variantx1 = Math.random() * 100;
        this.variantx2 = Math.random() * 100;
        this.varianty1 = Math.random() * 100;
        this.varianty2 = Math.random() * 100;
    }

    private createOval(x: number, y: number, w: number, h: number) {
        const xe = x + w; // x-end
        const ye = y + h; // y-end
        const xm = x + w / 2; // x-middle
        const ym = y + h / 2; // y-middle

        this.canvas.save();

        this.canvas.translate(this.width / 2, this.height / 2);

        // Rotate 1 degree
        this.canvas.rotate(this.rotation);

        // Move registration point back to the top left corner of canvas
        this.canvas.translate(-this.width / 2, -this.height / 2);

        this.canvas.beginPath();
        this.canvas.moveTo(x, ym);
        this.canvas.quadraticCurveTo(x, y, xm, y);
        this.canvas.quadraticCurveTo(xe, y, xe, ym);
        this.canvas.quadraticCurveTo(xe, ye, xm, ye);
        this.canvas.quadraticCurveTo(x, ye, x, ym);

        // this.canvas.strokeStyle = 1;
        this.canvas.fillStyle = 'rgba(255,255,255,0.01)';
        this.canvas.fill();
        this.canvas.stroke();
        this.canvas.restore();
    }

    public render() {
        // Create inside parts
        this.createOval(this.x, this.y, 12, 4);
    }

    public move() {
        this.x +=
            (Math.sin(this.progress / this.variantx1) *
                Math.cos(this.progress / this.variantx2)) /
            4;
        this.y +=
            (Math.sin(this.progress / this.varianty1) *
                Math.cos(this.progress / this.varianty2)) /
            4;

        if (this.x < 0 || this.x > this.width - this.radius) {
            return false;
        }

        if (this.y < 0 || this.y > this.height - this.radius) {
            return false;
        }
        this.render();
        this.progress++;
        return true;
    }
}
