import { BackgroundElement } from './BackgroundElement';

export class FishLarva extends BackgroundElement {
    private x: number = 0;
    private y: number = 0;
    private a: number = 0;
    private readonly s: number = 0;
    private readonly radius: number = 0;
    private readonly color: string;

    constructor(canvas: CanvasRenderingContext2D) {
        super(canvas);

        const random = Math.random();
        this.canvas = canvas;

        this.x = this.width / 2 + (Math.random() * 200 - Math.random() * 200);
        this.y = this.height / 2 + (Math.random() * 200 - Math.random() * 200);

        this.s = 0.8 + Math.random() * 0.6;
        this.a = 0;

        this.radius = random * 1.3;
        this.color = '#f69a34';
    }

    public render() {
        this.canvas.beginPath();
        this.canvas.arc(this.x, this.y, this.radius, 0, 2 * Math.PI);
        this.canvas.lineWidth = 2;
        this.canvas.fillStyle = this.color;
        this.canvas.fill();
        this.canvas.closePath();
    }

    public move() {
        this.x += Math.cos(this.a) * this.s;
        this.y += Math.sin(this.a) * this.s;
        this.a += Math.random() * 0.8 - 0.4;
        if (this.x < 0 || this.x > this.width - this.radius) {
            return false;
        }

        if (this.y < 0 || this.y > this.height - this.radius) {
            return false;
        }
        this.render();
        return true;
    }
}
