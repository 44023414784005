import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Windows } from '../../+state/windows/window.model';
import { selectOpenWindows } from '../../+state/windows/window.selectors';
import { windowSlice } from '../../+state/windows/window.slice';
import { Window } from './Window';
import { WINDOW_DEFINITION_MAP } from './Window.definitions';

export const WindowList: React.FC = () => {
    const openWindows = useSelector(selectOpenWindows);
    const dispatch = useDispatch();

    const focusWindow = useCallback(
        (window: Windows) => {
            dispatch(windowSlice.actions.focusWindow(window));
        },
        [dispatch]
    );

    const minimizeWindow = useCallback(
        (window: Windows): void => {
            dispatch(windowSlice.actions.minimizeWindow(window));
        },
        [dispatch]
    );

    const closeWindow = useCallback(
        (window: Windows): void => {
            dispatch(windowSlice.actions.closeWindow(window));
        },
        [dispatch]
    );

    const renderWindow = useCallback(
        (window: Windows) => {
            const windowDefinition = WINDOW_DEFINITION_MAP.get(window);
            if (!windowDefinition) {
                return null;
            }
            return (
                <Window
                    className={(windowDefinition.classes || []).join(' ')}
                    key={windowDefinition.label}
                    title={windowDefinition.label}
                    onFocus={() => focusWindow(window)}
                    onMinimize={() => minimizeWindow(window)}
                    onClose={() => closeWindow(window)}
                >
                    {windowDefinition.content}
                </Window>
            );
        },
        [focusWindow, minimizeWindow, closeWindow]
    );

    return (
        <Fragment>{openWindows.map((window) => renderWindow(window))}</Fragment>
    );
};
