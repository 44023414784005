import { configureStore } from '@reduxjs/toolkit';
import { IWindowState, windowSlice } from './windows/window.slice';

export interface IStoreState {
    windows: IWindowState;
}

export const store = configureStore({
    reducer: {
        windows: windowSlice.reducer,
    },
});
