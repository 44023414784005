import { BackgroundElement } from './BackgroundElement';

interface IFishEggConfig {
    offset1: number;
    offset2: number;
    offset3: number;
    radius1: number;
    radius2: number;
    radius3: number;
}

export class FishEgg extends BackgroundElement {
    private progress: number = 0;
    private x: number;
    private y: number;
    private readonly radius: number;
    private readonly variantx1: number;
    private readonly variantx2: number;
    private readonly varianty1: number;
    private readonly varianty2: number;
    private fishEgg: IFishEggConfig;

    constructor(canvas: CanvasRenderingContext2D) {
        super(canvas);

        // Set position
        this.x = this.width / 2 + (Math.random() * 300 - Math.random() * 300);
        this.y =
            this.height / 2 +
            ((Math.random() * this.height) / 4 -
                (Math.random() * this.height) / 4);

        // Dimension
        this.radius = 12 + Math.random() * 6;

        // Setting
        this.fishEgg = {
            offset1:
                Math.random() > 0.5
                    ? 0.5 + Math.random() * 3
                    : 0.5 + Math.random() * -3,
            offset2:
                Math.random() > 0.5
                    ? 0.5 + Math.random() * 3
                    : 0.5 + Math.random() * -3,
            offset3:
                Math.random() > 0.5
                    ? 0.5 + Math.random() * 3
                    : 0.5 + Math.random() * -3,
            radius1: 0.5 + Math.random() * 5,
            radius2: 0.5 + Math.random() * 5,
            radius3: 0.5 + Math.random() * 5,
        };
        this.variantx1 = Math.random() * 100;
        this.variantx2 = Math.random() * 100;
        this.varianty1 = Math.random() * 100;
        this.varianty2 = Math.random() * 100;
    }

    private createCircle(x: number, y: number, r: number, c: string) {
        this.canvas.beginPath();
        this.canvas.fillStyle = c;
        this.canvas.arc(x, y, r, 0, Math.PI * 2, false);
        this.canvas.fill();
        this.canvas.closePath();
    }

    private createEyes() {
        this.createCircle(
            this.x + this.fishEgg.offset2,
            this.y + this.fishEgg.offset2,
            this.fishEgg.radius2 + 4,
            'rgba(241, 242, 244, 0.06)'
        );
        this.createCircle(
            this.x + this.fishEgg.offset3,
            this.y + this.fishEgg.offset3,
            this.fishEgg.radius3 + 2,
            'rgba(255, 204, 67, 0.08)'
        );
        this.createCircle(
            this.x + Math.random() * this.fishEgg.offset1, // this.progress / 350 ????
            this.y + Math.random() * this.fishEgg.offset1,
            this.fishEgg.radius1,
            'rgba(152, 19, 4, 0.19)'
        );
    }

    public render() {
        // Create inside parts
        this.createEyes();

        this.canvas.beginPath();
        const c = '130, 151, 180';
        const rad = this.canvas.createRadialGradient(
            this.x,
            this.y,
            this.radius,
            this.x,
            this.y,
            1
        );
        rad.addColorStop(0, `rgba(${c},0.09)`);
        rad.addColorStop(0.9, `rgba(${c},0)`);
        this.canvas.lineWidth = Math.random() * 2.2;
        this.canvas.fillStyle = rad;
        this.canvas.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        this.canvas.fill();
        this.canvas.strokeStyle = 'rgba(255, 255, 217, 0.05)';
        this.canvas.stroke();
        this.canvas.closePath();
    }

    public move() {
        this.x +=
            (Math.sin(this.progress / this.variantx1) *
                Math.cos(this.progress / this.variantx2)) /
            8;
        this.y +=
            (Math.sin(this.progress / this.varianty1) *
                Math.cos(this.progress / this.varianty2)) /
            8;

        if (this.x < 0 || this.x > this.width - this.radius) {
            return false;
        }

        if (this.y < 0 || this.y > this.height - this.radius) {
            return false;
        }
        this.render();
        this.progress++;
        return true;
    }
}
