import Prism from 'prismjs';
import 'prismjs/themes/prism-okaidia.css';
import React, { Component } from 'react';
/* eslint import/no-webpack-loader-syntax: off */
// @ts-ignore
import sourceCode from 'val-loader!../../self.val';
import './SelfDocument.css';

function generateWaitTime() {
    return Math.random() * 50 + 50;
}

function appCode() {
    let result = '';
    for (let i = 0; i < sourceCode.length; i++) {
        // @ts-ignore
        result = `${result}// ${sourceCode[i].name}\n\n${sourceCode[i].content}\n\n`;
    }
    return result;
}

interface ISelfDocumentState {
    rendered: number;
    appCode: string;
}

class SelfDocument extends Component<any, ISelfDocumentState> {
    private containerRef: HTMLDivElement | null | undefined;
    private ticker: number | undefined;
    private nextTick: number | undefined;

    constructor(props: {}) {
        super(props);
        this.state = {
            appCode: appCode(),
            rendered: 0,
        };
    }

    public componentDidMount(): void {
        this.next();
    }

    public componentWillUnmount(): void {
        if (this.ticker) {
            window.cancelAnimationFrame(this.ticker);
        }
    }

    private next() {
        if (this.state.rendered >= this.state.appCode.length) {
            return;
        }
        this.ticker = window.requestAnimationFrame(
            this.updateTyping.bind(this)
        );
    }

    private updateTyping(timestamp: number) {
        if (!this.nextTick) {
            this.nextTick = timestamp + generateWaitTime();
            this.next();
            return;
        }
        if (timestamp < this.nextTick) {
            this.next();
            return;
        }
        this.setState((state) => ({
            rendered: Math.min(state.rendered + 1, state.appCode.length),
        }));
        this.nextTick = timestamp + generateWaitTime();
        this.next();
        if (this.containerRef) {
            this.containerRef.scrollTo(0, this.containerRef.scrollHeight);
        }
    }

    private highlightedCode() {
        const visibleCode = this.state.appCode.substr(0, this.state.rendered);
        // Prism.js is quite cool, I liked the meta-ness of the page ;)
        return Prism.highlight(
            visibleCode,
            Prism.languages.javascript,
            'typescript'
        );
    }

    public render() {
        return (
            <div
                className="SelfDocument"
                ref={(el) => (this.containerRef = el)}
            >
                <pre>
                    <code
                        className="language-javascript"
                        dangerouslySetInnerHTML={{
                            __html: this.highlightedCode(),
                        }}
                    />
                </pre>
            </div>
        );
    }
}

export default SelfDocument;
