import { createSlice } from '@reduxjs/toolkit';
import { Windows } from './window.model';

export interface IWindowState {
    openWindows: Windows[];
    startMenuOpen: boolean;
    windows: Windows[];
}

export const windowSlice = createSlice({
    initialState: {
        openWindows: [],
        startMenuOpen: false,
        windows: [],
    } as IWindowState,
    name: 'windows',
    reducers: {
        closeWindow: (state, { payload }) => {
            let windowIndex = state.openWindows.findIndex((w) => payload === w);
            state.openWindows.splice(windowIndex, 1);
            windowIndex = state.openWindows.findIndex((w) => payload === w);
            state.windows.splice(windowIndex, 1);
        },
        focusWindow: (state, { payload }) => {
            const windowIndex = state.openWindows.findIndex(
                (w) => payload === w
            );
            if (windowIndex < 0) {
                if (state.windows.includes(payload)) {
                    state.openWindows.unshift(payload);
                    state.startMenuOpen = false;
                }
                return;
            }
            state.openWindows.splice(windowIndex, 1);
            state.openWindows.push(payload);
        },
        minimizeWindow: (state, { payload }) => {
            const windowIndex = state.openWindows.findIndex(
                (w) => payload === w
            );
            state.openWindows.splice(windowIndex, 1);
            state.startMenuOpen = false;
        },
        openWindow: (state, { payload }) => {
            const windowIndex = state.windows.findIndex((w) => payload === w);
            if (windowIndex >= 0) {
                windowSlice.reducer(
                    state,
                    windowSlice.actions.focusWindow(payload)
                );
            } else {
                state.windows.unshift(payload);
                state.openWindows.push(payload);
                state.startMenuOpen = false;
            }
        },
        setStartMenu: (state, { payload }) => {
            state.startMenuOpen = payload;
        },
        toggleStartMenu: (state) => {
            state.startMenuOpen = !state.startMenuOpen;
        },
    },
});
