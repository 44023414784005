import {
    faWindowClose,
    faWindowMaximize,
    faWindowMinimize,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useRef, useState } from 'react';

import styles from './Window.module.scss';

export interface IWindowProps {
    className?: string;
    title: string;
    onFocus: () => void;
    onMinimize: () => void;
    onClose: () => void;
}

interface IMouseState {
    cleanup: () => void;
    dX: number;
    dY: number;
    left: number;
    top: number;
    x: number;
    y: number;
}

export const Window: React.FC<IWindowProps> = ({
    className,
    title,
    onFocus,
    onMinimize,
    onClose,
    children,
}) => {
    const [left, setLeft] = useState(20);
    const [top, setTop] = useState(20);
    const [maximized, setMaximized] = useState(false);

    const mouseState = useRef<IMouseState | null>(null);
    const animationFrame = useRef<number | null>(null);

    const onMouseMove = useCallback((event: MouseEvent): void => {
        if (!mouseState.current) {
            return;
        }
        mouseState.current.dX = event.pageX - mouseState.current.x;
        mouseState.current.dY = event.pageY - mouseState.current.y;

        event.preventDefault();
        event.stopPropagation();

        if (!!animationFrame.current) {
            return;
        }
        animationFrame.current = window.requestAnimationFrame(() => {
            if (!mouseState.current) {
                animationFrame.current = null;
                return;
            }

            setLeft(mouseState.current.left + mouseState.current.dX);
            setTop(mouseState.current.top + mouseState.current.dY);

            animationFrame.current = null;
        });
    }, []);

    const onMouseUp = useCallback((): void => {
        if (mouseState.current) {
            mouseState.current.cleanup();
        }
        mouseState.current = null;
    }, []);

    const onMouseDown = useCallback(
        (event: React.MouseEvent<any, MouseEvent>): void => {
            if (maximized) {
                return;
            }
            const mouseMove = (event: MouseEvent) => onMouseMove(event);
            const mouseOut = () => onMouseUp();
            const cleanUp = () => {
                window.document.removeEventListener('mousemove', mouseMove);
                window.document.removeEventListener('mouseleave', mouseOut);
                window.document.removeEventListener('mouseup', mouseOut);
            };
            mouseState.current = {
                cleanup: cleanUp,
                dX: 0,
                dY: 0,
                left,
                top,
                x: event.pageX,
                y: event.pageY,
            };
            window.document.addEventListener('mousemove', mouseMove);
            window.document.addEventListener('mouseleave', mouseOut, {
                passive: true,
            });
            window.document.addEventListener('mouseup', mouseOut, {
                passive: true,
            });
            event.preventDefault();
            event.stopPropagation();
        },
        [onMouseMove, onMouseUp, left, maximized, top]
    );

    const onMaximize = useCallback(
        (event: React.MouseEvent<any, MouseEvent>) => {
            setMaximized(!maximized);
            event.stopPropagation();
        },
        [maximized]
    );

    const _onMinimize = useCallback(
        (event: React.MouseEvent<any, MouseEvent>) => {
            onMinimize();
            event.stopPropagation();
        },
        [onMinimize]
    );

    const _onClose = useCallback(
        (event: React.MouseEvent<any, MouseEvent>) => {
            onClose();
            event.stopPropagation();
        },
        [onClose]
    );

    return (
        <div
            className={[
                styles.window,
                maximized && styles.windowMaximized,
                className,
            ]
                .filter((c) => !!c)
                .join(' ')}
            style={{ top, left }}
            onClick={onFocus}
        >
            <div className={styles.window__bar} onMouseDown={onMouseDown}>
                <span className={styles.window__title}>{title}</span>
                <FontAwesomeIcon
                    className={styles.window__icon}
                    icon={faWindowMinimize}
                    onClick={_onMinimize}
                />
                <FontAwesomeIcon
                    className={styles.window__icon}
                    icon={faWindowMaximize}
                    onClick={onMaximize}
                />
                <FontAwesomeIcon
                    className={styles.window__icon}
                    icon={faWindowClose}
                    onClick={_onClose}
                />
            </div>
            <div className={styles.window__content}>{children}</div>
        </div>
    );
};
