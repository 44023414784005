import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';

import styles from './Icon.module.scss';

export interface IIconProps {
    label: string;
    icon: IconDefinition;
    onClick: () => void;
}

export const Icon: React.FC<IIconProps> = ({ label, icon, onClick }) => {
    const [active, setActive] = useState(false);

    const onMouseDown = useCallback(
        (event: React.MouseEvent<any, MouseEvent>): void => {
            setActive(true);
            event.preventDefault();
            event.stopPropagation();
        },
        []
    );

    const onMouseOut = useCallback((): void => {
        setActive(false);
    }, []);

    const onDoubleClick = useCallback((): void => {
        if (onClick) {
            onClick();
        }
    }, [onClick]);

    return (
        <span
            className={[styles.icon, active && styles.iconActive]
                .filter((e) => !!e)
                .join(' ')}
            onDoubleClick={onDoubleClick}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseOut}
            onMouseOut={onMouseOut}
        >
            <FontAwesomeIcon className={styles.icon__icon} icon={icon} />
            <span className={styles.icon__label}>{label}</span>
        </span>
    );
};
