import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from '../store';

const selectWindowState = (state: IStoreState) => state.windows;

export const selectOpenWindows = createSelector(
    [selectWindowState],
    (state) => state.openWindows
);

export const selectWindows = createSelector(
    [selectWindowState],
    (state) => state.windows
);

export const selectStartMenuOpen = createSelector(
    [selectWindowState],
    (state) => state.startMenuOpen
);
