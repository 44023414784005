import React from 'react';

import Mine, { IMine } from './Mine';
import styles from './Minefield.module.scss';

export type Field = Array<Array<IMine>>;

export interface IMinefieldProps {
    field: Field;
    onMineClick: (row: number, column: number) => void;
    onMineSafe: (row: number, column: number) => void;
    onMineCascade: (row: number, column: number) => void;
}

export const Minefield: React.FC<IMinefieldProps> = React.memo(
    ({ field, onMineClick, onMineCascade, onMineSafe }) => {
        return (
            <div className={styles.minefield}>
                <div className={styles.minefield__inner}>
                    {field.map((row, y) => (
                        <div className={styles.minefield__row} key={y}>
                            {row.map((mine, x) => (
                                <Mine
                                    key={`${x}_${y}`}
                                    mine={mine}
                                    onClick={() => onMineClick(y, x)}
                                    onSafe={() => onMineSafe(y, x)}
                                    onCascade={() => onMineCascade(y, x)}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
);

export default Minefield;
