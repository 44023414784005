import React, { useCallback, useState } from 'react';

import {
    cloneField,
    each,
    eachAdjacent,
    ensureSafeStart,
    generateField,
    getAdjacent,
    isSafe,
} from './Logic';
import { MineStates } from './Mine';
import Minefield, { Field } from './Minefield';
import styles from './Minesweeper.module.scss';

interface IMinesweeperMode {
    bombs: number;
    columns: number;
    label: string;
    rows: number;
}

export interface IMinesweeperState {
    won: boolean;
    started: boolean;
    gameOver: boolean;
    revealable: number;
    field: Field;
    mode: IMinesweeperMode;
}

const MODES: IMinesweeperMode[] = [
    {
        bombs: 20 * 20 * 0.15,
        columns: 20,
        label: 'Easy',
        rows: 20,
    },
    {
        bombs: 25 * 30 * 0.2,
        columns: 30,
        label: 'Medium',
        rows: 25,
    },
    {
        bombs: 30 * 35 * 0.25,
        columns: 35,
        label: 'Hard',
        rows: 25,
    },
];

function newGameState(mode: IMinesweeperMode): IMinesweeperState {
    return {
        field: generateField(mode.rows, mode.columns, mode.bombs),
        gameOver: false,
        mode: mode,
        revealable: mode.rows * mode.columns - mode.bombs,
        started: false,
        won: false,
    };
}

export const Minesweeper: React.FC = () => {
    const [gameState, setGameState] = useState<IMinesweeperState>(
        newGameState(MODES[0])
    );

    const onMineClick = useCallback(
        (
            field: Field,
            row: number,
            column: number,
            recurse?: boolean
        ): void => {
            let mine = field[row][column];
            let gameOver = gameState.gameOver;
            if (
                mine.safe ||
                mine.revealed ||
                gameState.gameOver ||
                gameState.won
            ) {
                return;
            }
            if (!gameState.started && !recurse) {
                field = ensureSafeStart(
                    field,
                    row,
                    column,
                    gameState.mode.rows,
                    gameState.mode.columns,
                    gameState.mode.bombs
                );
                mine = field[row][column];
            }
            mine.revealed = true;
            if (mine.state === MineStates.Bomb) {
                gameOver = true;
                each(field, (m) => {
                    if (m.state === MineStates.Bomb) {
                        m.revealed = true;
                    }
                });
            } else if (mine.adjacentMines === 0) {
                eachAdjacent(field, row, column, (y, x) => {
                    onMineClick(field, y, x, true);
                });
            }
            if (!recurse) {
                let revealedFields = 0;
                each(field, (mine) => {
                    if (mine.revealed) {
                        revealedFields++;
                    }
                });
                setGameState(
                    Object.assign({}, gameState, {
                        field: cloneField(field),
                        gameOver,
                        started: true,
                        won:
                            !gameOver &&
                            revealedFields === gameState.revealable,
                    })
                );
            }
        },
        [gameState]
    );

    const onMineSafe = useCallback(
        (y: number, x: number): void => {
            const mine = gameState.field[y][x];
            if (mine.revealed || gameState.gameOver || gameState.won) {
                return;
            }
            const newField = cloneField(gameState.field);
            newField[y][x] = Object.assign({}, mine, { safe: !mine.safe });
            setGameState(Object.assign({}, gameState, { field: newField }));
        },
        [gameState]
    );

    const onMineCascade = useCallback(
        (row: number, column: number): void => {
            const mine = gameState.field[row][column];
            const adjacentSafe = getAdjacent(
                gameState.field,
                row,
                column,
                isSafe
            );
            if (
                !mine.revealed ||
                adjacentSafe !== mine.adjacentMines ||
                gameState.gameOver ||
                gameState.won
            ) {
                return;
            }
            eachAdjacent(gameState.field, row, column, (y, x) => {
                onMineClick(gameState.field, y, x);
            });
        },
        [gameState, onMineClick]
    );

    return (
        <div className={styles.minesweeper__wrapper}>
            <div className={styles.minesweeper}>
                <div className={styles.minesweeper__header}>
                    <select
                        name={styles.minesweeper_mode}
                        id="minesweeper_mode"
                        value={gameState.mode.label}
                        onChange={(value) =>
                            setGameState(
                                newGameState(
                                    MODES.find(
                                        (mode) =>
                                            mode.label === value.target.value
                                    ) || gameState.mode
                                )
                            )
                        }
                    >
                        {MODES.map((mode) => (
                            <option key={mode.label} value={mode.label}>
                                {mode.label}
                            </option>
                        ))}
                    </select>
                    <button
                        onClick={() =>
                            setGameState(newGameState(gameState.mode))
                        }
                    >
                        NEW GAME
                    </button>
                    {gameState.gameOver ? (
                        <span className={styles.minesweeper__gameOver}>
                            GAME OVER
                        </span>
                    ) : null}
                    {gameState.won ? (
                        <span className={styles.minesweeper__won}>YOU WIN</span>
                    ) : null}
                </div>
                <Minefield
                    field={gameState.field}
                    onMineClick={(y, x) => onMineClick(gameState.field, y, x)}
                    onMineCascade={(y, x) => onMineCascade(y, x)}
                    onMineSafe={(y, x) => onMineSafe(y, x)}
                />
            </div>
        </div>
    );
};

export default Minesweeper;
