import { IconDefinition } from '@fortawesome/free-brands-svg-icons';

export enum Windows {
    CV = 'CV',
    SelfDocument = 'SelfDocument.tsx',
    Minesweeper = 'Minesweeper',
}

export interface IWindowDefinition {
    content: any;
    icon: IconDefinition;
    label: Windows;
    classes?: string[];
}
