import { IMine, MineStates } from './Mine';
import { Field } from './Minefield';

export function generateField(
    rows: number,
    columns: number,
    bombs: number
): Field {
    const field: Field = [];
    if (bombs > rows * columns) {
        return field;
    }
    for (let i = 0; i < rows; i++) {
        const row: Array<IMine> = [];
        field.push(row);
        for (let n = 0; n < columns; n++) {
            row.push(generateMine());
        }
    }
    let assignedBombs = 0;
    while (assignedBombs < bombs) {
        const x = Math.floor(Math.random() * columns);
        const y = Math.floor(Math.random() * rows);
        const selectedSpace = field[y][x];
        if (selectedSpace.state !== MineStates.Bomb) {
            selectedSpace.state = MineStates.Bomb;
            assignedBombs++;
        }
    }
    for (let i = 0; i < rows; i++) {
        for (let n = 0; n < columns; n++) {
            const currentMine = field[i][n];
            currentMine.adjacentMines = getAdjacent(field, i, n, isBomb);
            if (
                currentMine.adjacentMines > 0 &&
                currentMine.state !== MineStates.Bomb
            ) {
                currentMine.state = MineStates.Number;
            }
        }
    }
    return field;
}

export function ensureSafeStart(
    field: Field,
    y: number,
    x: number,
    rows: number,
    columns: number,
    bombs: number
): Field {
    let resultingField = field;
    while (
        resultingField[y][x].adjacentMines !== 0 ||
        isBomb(resultingField, y, x)
    ) {
        resultingField = generateField(rows, columns, bombs);
    }
    return resultingField;
}

export function generateMine(): IMine {
    return {
        adjacentMines: 0,
        revealed: false,
        safe: false,
        state: MineStates.Empty,
    };
}

export function getAdjacent(
    field: Field,
    row: number,
    column: number,
    condition: (field: Field, row: number, column: number) => boolean
): number {
    let adjacent = 0;
    eachAdjacent(field, row, column, (y, x) => {
        if (condition(field, y, x)) {
            adjacent++;
        }
    });
    return adjacent;
}

export function eachAdjacent(
    field: Field,
    row: number,
    column: number,
    fn: (row: number, column: number) => void
) {
    const startingRow = Math.max(0, row - 1);
    const endRow = Math.min(field.length - 1, row + 1);
    const startingColumn = Math.max(0, column - 1);
    const endColumn = Math.min(field[0].length - 1, column + 1);
    for (let y = startingRow; y <= endRow; y++) {
        for (let x = startingColumn; x <= endColumn; x++) {
            if (row === y && column === x) {
                continue;
            }
            fn(y, x);
        }
    }
}

export function each(
    field: Field,
    fn: (mine: IMine, row: number, column: number) => void
): void {
    for (let y = 0; y < field.length; y++) {
        for (let x = 0; x < field[0].length; x++) {
            fn(field[y][x], y, x);
        }
    }
}

export function isBomb(field: Field, row: number, column: number): boolean {
    if (
        row < 0 ||
        row >= field.length ||
        column < 0 ||
        column >= field[0].length
    ) {
        return false;
    }
    const selectedSpace = field[row][column];
    return selectedSpace.state === MineStates.Bomb;
}

export function isSafe(field: Field, row: number, column: number): boolean {
    if (
        row < 0 ||
        row >= field.length ||
        column < 0 ||
        column >= field[0].length
    ) {
        return false;
    }
    const selectedSpace = field[row][column];
    return selectedSpace.safe;
}

export function cloneField(field: Field): Field {
    return field.map((row) => row.map((mine) => Object.assign({}, mine)));
}
