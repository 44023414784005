import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faBomb, faCat, faCode } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Windows } from '../../+state/windows/window.model';
import CV from '../cv/CV';
import Minefield from '../minesweeper/Minesweeper';
import SelfDocument from '../selfdocument/SelfDocument';
import windowStyles from './Window.module.scss';

interface IWindowDefinition {
    content: any;
    icon: IconDefinition;
    label: Windows;
    classes?: string[];
}

export const WINDOW_DEFINITION_MAP = new Map<Windows, IWindowDefinition>([
    [
        Windows.CV,
        {
            classes: [windowStyles.windowAllowOverflow],
            content: <CV />,
            icon: faCat,
            label: Windows.CV,
        },
    ],
    [
        Windows.SelfDocument,
        {
            content: <SelfDocument />,
            icon: faCode,
            label: Windows.SelfDocument,
        },
    ],
    [
        Windows.Minesweeper,
        {
            content: <Minefield />,
            icon: faBomb,
            label: Windows.Minesweeper,
        },
    ],
]);
