import React, { ReactNode } from 'react';

import styles from './CVEntry.module.scss';

export interface ICVEntryProps {
    timeRange?: string;
    title?: string;
    logo?: string;
    children: ReactNode;
}

export default function CVEntry(props: ICVEntryProps) {
    return (
        <div className={styles.cvList__entry}>
            <div className={styles.cvList__side}>
                {props.timeRange ? (
                    <div className={styles.cvList__dateRange}>
                        {props.timeRange}
                    </div>
                ) : null}
                {props.logo ? <img src={props.logo} alt={props.title} /> : null}
            </div>
            <div className={styles.cvList__description}>
                {props.title ? <h2>{props.title}</h2> : null}

                {props.children}
            </div>
        </div>
    );
}
