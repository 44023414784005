import React from 'react';

import styles from './CV.module.scss';
import CVEntry from './CVEntry';

import bibLogo from './logos/bib_logo.png';
import maruLogo from './logos/maru_matchbox_logo.png';
import senseyeLogo from './logos/senseye_logo.png';
import sipsynergyLogo from './logos/sipsynergy_logo.png';
import solentLogo from './logos/solent_logo.png';
import headshot from './me.jpg';

export const CV = () => {
    return (
        <div className={styles.cv__outer}>
            <img
                className={styles.cv__photo}
                src={headshot}
                alt="Florian Adomeit"
            />
            <div className={styles.cv}>
                <div className={styles.cv_inner}>
                    <h1>Work Experience</h1>
                    <div>
                        <CVEntry
                            timeRange="2018 – Present"
                            title="Senseye"
                            logo={senseyeLogo}
                        >
                            <p>
                                At senseye I was initially heavily involved with
                                completing the transition from AngularJS to
                                Angular. My responsibilities mainly included
                                work on the Angular app and implementing new
                                features on an API of REST and GraphQL
                                endpoints, while writing presentational and
                                container components in Typescript. The work
                                involved Jest, Cypress, Webpack, RxJS, Storybook
                                and NGXS.
                            </p>
                        </CVEntry>
                        <CVEntry
                            timeRange="2016 – 2018"
                            title="sipsynergy"
                            logo={sipsynergyLogo}
                        >
                            <p>
                                Sipsynergy was in the process of breaking down
                                their monolithic application into more
                                manageable microservices. I was brought on to
                                update the frontend code from Angular 1. For
                                this I presented them with prototypes in React
                                and Vue.js and we decided on the later. My time
                                here involved spearheading the new frontend
                                while upgrading and unifying their interfaces to
                                a more modern design.
                            </p>
                        </CVEntry>
                        <CVEntry
                            timeRange="2011 - 2016"
                            title="Maru/edr"
                            logo={maruLogo}
                        >
                            <p>
                                Working closely with the Design team to
                                implement usable and accessible interfaces and I
                                was responsible for the survey frontend written
                                in AngularJS. In addition to this I contribute
                                to our SaaS systems based on Zend, Symfony and
                                TypeScript/AngularJS and tests implemented with
                                a combination of PHPUnit, Karma, Jasmine,
                                Cucumber, Selenium and Protractor.
                            </p>
                            <ul>
                                <li>
                                    2015 - <strong>UI Engineer</strong>
                                </li>
                                <li>
                                    2013 - <strong>Analyst Developer</strong>
                                </li>
                                <li>
                                    2012 - <strong>Developer</strong>
                                </li>
                                <li>
                                    2011 - <strong> Junior developer</strong>
                                </li>
                            </ul>
                        </CVEntry>
                        <CVEntry timeRange="2010" title="triplesense">
                            <p>
                                Two month internship in a web agency creating
                                the prototype for their new agency website.
                            </p>
                        </CVEntry>
                        <CVEntry
                            timeRange="2008/9"
                            title="Heinz Nixdorf Institute"
                        >
                            <p>
                                Part time work developing a workflow modelling
                                tool in C#
                            </p>
                        </CVEntry>
                    </div>
                    <h1>Education</h1>
                    <div>
                        <CVEntry
                            timeRange="2010 - 2011"
                            title="Bsc (Hons) Web Development"
                            logo={solentLogo}
                        >
                            <h3>Southampton Solent University</h3>
                            <p>
                                Courses: Emerging Web Technologies, Human
                                Computer Interaction, Software Systems
                                Development, Mobile Software Applications and
                                Architecture
                            </p>
                        </CVEntry>
                        <CVEntry
                            timeRange="2008 - 2010"
                            title="Staatlichgeprüfter Informatiker Multimedia"
                            logo={bibLogo}
                        >
                            <h3>b.i.b. International College, Paderborn</h3>
                            <p>
                                Vocational education covering programming,
                                design and usability
                            </p>
                        </CVEntry>
                    </div>

                    <h1>Skills</h1>

                    <CVEntry>
                        <p>
                            <strong>Languages</strong> German, English <br />
                            <strong>Software</strong> JavaScript, TypeScript,
                            node.js, Webpack, Yarn, Grunt, Gulp, Vue.JS,
                            Angular, AngularJS, React, Redux, SQL, HTML, CSS,
                            SCSS, Less, Git, Subversion, PHP, Docker, Go, d3
                            <br />
                        </p>
                    </CVEntry>
                </div>
            </div>
        </div>
    );
};

export default CV;
