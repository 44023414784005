export class BackgroundElement {
    protected canvas: CanvasRenderingContext2D;
    protected width: number;
    protected height: number;

    constructor(canvas: CanvasRenderingContext2D) {
        this.canvas = canvas;

        // Set position
        // Get viewport size
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    }

    public render() {
        //
    }

    public move() {
        //
    }
}
